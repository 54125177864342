<script>
import draggable from 'vuedraggable'
import { v4 as uuidv4 } from 'uuid'
// import ManageEvent from '@/mixins/manageEvents.js'
// import updateTableau from '@/mixins/updateTableau.js'
import EditeurTableauV2 from '@/mixins/editeurTableauV2.js'
import eleveSelectionParent from '@/mixins/eleveSelectionParent.js'
import FormatDate from '@/mixins/formatDate.js'
// import openLink from '@/mixins/openLink.js'

// import ManageListEleve from '@/components/eleves/manageListEleve'
// import TableauPreviewExpanded from '@/components/tableauxBlancs/tableauPreviewExpanded'
import SelectElevesV2 from '@/components/dialogs/actions/selectElevesV2'
import GroupListSelectionPreview from '@/components/groupes/GroupListSelectionPreview'

export default {
  name: 'tableauEdition',
  mixins: [
    // ManageEvent,
    // updateTableau,
    EditeurTableauV2,
    eleveSelectionParent,
    FormatDate
    // openLink
  ],
  components: {
    SelectElevesV2,
    draggable,
    GroupListSelectionPreview
  //   ManageListEleve,
  //   TableauPreviewExpanded,
  },
  data () {
    return {
      creationLoading: false,
      searchDevoirs: null,
      validForm: true,
      titreRules: [v => !!v || 'titre-requis'],
      dateDebutRules: [v => !!v || 'date-requis'],
      titre: null,
      description: null,
      menuDateDebut: false,
      menuDateFin: false,
      dateDebut: null,
      dateFin: null,
      tempsMoyen: null,
      ressources: null,
      selectedNiveaux: null,
      selectedMatieres: null,
      // searchEleves: null,
      exercices: [],
      showError: false,
      error: false,
      finish: false,
      // page: 1,
      devoir: null,
      backupData: null,
      showSelectEleves: false
    }
  },
  computed: {
    showElevesSelection: {
      set (val) { this.$store.commit('Dialogs/setElevesSelection', val) },
      get () { return this.$store.state.Dialogs.showElevesSelection }
    },
    selectedEleves: {
      set (val) { this.$store.commit('Dialogs/setSelectedEleves', val) },
      get () { return this.$store.state.Dialogs.selectedEleves }
    },
    showTableauSelection: {
      set (val) { this.$store.commit('Dialogs/setShowTableauSelection', val) },
      get () { return this.$store.state.Dialogs.showTableauSelection }
    },
    selectedTableaux: {
      set (val) {
        this.$store.commit('Dialogs/setSelectedTableaux', val)
      },
      get () { return this.$store.state.Dialogs.selectedTableaux }
    }
  },
  async created () {
    if (this.$route.query && this.$route.query.id) {
      await this.$store.dispatch('App/fetchRessourcesExercices')
      this.backupData = await this.$apollo.query({
        query: require('@/graphql/queries/v2/calendrierCalendrierElements.gql'),
        variables: {
          filtre: {
            identifiants: [this.$route.query.id]
          }
        }
      }).then(({ data }) => {
        return data.calendrierCalendrierElements
      })
      this.$nextTick(() => {
        this.resetFromIdVal()
      })
    }
    if (this.$route?.query?.t && this.$route?.query?.titre) {
      this.titre = 'tableaux.copy-of' + this.$route.query.titre
    }
  },
  beforeDestroy () {
    // this.$store.commit('Dialogs/resetSelectedTableaux')
  },
  beforeRouteLeave (to, from, next) {
    if (!this.finish) {
      const answer = window.confirm('messages.confirm-sorti')
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  methods: {
    resetFromIdVal () {
      this.titre = this.backupData.resultat[0].titre
      this.description = this.backupData.resultat[0].description
      this.dateDebut = this.backupData.resultat[0].debutAt ? this.backupData.resultat[0].debutAt.split('T')[0] : null
      this.dateFin = this.backupData.resultat[0].finAt ? this.backupData.resultat[0].finAt.split('T')[0] : null
      // const exList = []
      this.$store.commit('Dialogs/resetSelectedTableaux')
      // this.backupData.resultat.forEach((res) => {
      //   let elem = res.meta.split('/')[4]
      //   elem = elem.substring(0, elem.length - 1)
      //   let find = this.$store.state.App.treeviewExercices.forEach((ex) => {
      //     if (ex.id === elem) {
      //       exList.push(ex)
      //     } else {
      //       find = ex.children.find(c => c.id === elem)
      //       if (find) {
      //         exList.push(find)
      //       }
      //     }
      //   })
      // })
      // on peut avoir des doublons si exercices fait pour plusieurs classes
      this.selectedTableaux = this.backupData.resultat
      const tempEleves = []
      this.backupData.resultat.forEach((exercice) => {
        if (exercice.elementsUsagers && exercice.elementsUsagers.total > 0) {
          exercice.elementsUsagers.resultat.forEach((usager) => {
            if (tempEleves.findIndex(e => e.id === usager.usager.id) === -1) {
              tempEleves.push({
                id: usager.usager.id, name: usager.usager, type: 'eleve', calendrier: exercice.calendrier, classe: exercice.calendrier.proprietaire
              })
            }
          })
        }
      })
      this.selectedEleves = [...tempEleves]
    },
    removeFromEleveList (id) {
      let index = this.selectedEleves.findIndex(el => el.id === id)
      this.selectedEleves.splice(index, 1)
    },
    previewExercice (et) {
      let temp = 'https://www.laclassealamaison.com/'
      if (et.type === 'Collection') {
        temp += 'collection/' + et.slug
      } else if (et.type === 'Série') {
        temp += 'lecon/' + et.slug
      } else {
        temp += 'lecon/' + et.parentslug + '/graph/' + et.idtab
      }
      temp += '?dokUANE=' + this.$store.state.User.profile.info.username + '&access=' + this.$store.state.User.profile.info.accessCode
      this.writeTokenInCookiesAndOpenLink(temp)
    },
    async validateCreation () {
      if (this.$refs.creationForm.validate() || this.selectedTableaux) {
        this.creationLoading = true
        if (this.$route.query && this.$route.query.id) {
          this.updateTableau()
        } else {
          this.createOrDuplicateTableauBlancV2()
        }
      } else {
        console.error('erreur validate creation')
      }
    },
    ajoutDossier () {
      return new Promise((resolve, reject) => {
        this.createDossier()
          .then((data) => {
            if (data !== true) {
              resolve(data)
            } else {
              this.error = true
              reject(new Error())
            }
          })
          .catch((e) => {
            this.error = true
          })
      })
    },
    ajouttableau (id) {
      return new Promise((resolve, reject) => {
        this.createTableau(id)
          .then((data) => {
            if (data !== true) {
              resolve(data)
            } else {
              this.error = true
              reject(new Error())
            }
          })
          .catch((e) => {
            this.error = true
          })
      })
    },
    async dupliquerTableau (dossierId, selectedId = null) {
      const { data } = await this.$apollo.mutate({
        mutation: require('@/graphql/mutations/v2/dupliquerSalleClasseEnregistrementTableauBlanc.gql'),
        variables: {
          dossier: dossierId,
          id: selectedId === null ? this.$route?.query?.t : selectedId,
          boutiqueItem: selectedId === null ? this.$route?.query?.itemSlug : null
        }
      })
      const res = data.dupliquerSalleClasseEnregistrementTableauBlanc
      return res?.id
    },
    async creationTableau () {
      this.finish = false
      // on crée le dossier puis le tableau et ensuite on va créer le calendrier element

      const dossier = await this.ajoutDossier()
      if (!this.error) {
        const tableauBlancId = this.selectedTableaux?.tableauxBlancs?.[0]?.id
        if ((this.$route?.query?.t && this.$route?.query?.titre) || tableauBlancId) {
          await this.dupliquerTableau(dossier, tableauBlancId)
        } else {
          await this.ajouttableau(dossier)
        }
      }
      const identifiant = uuidv4()
      const listCal = this.selectedEleves && this.selectedEleves.length > 0 ? [...new Set(this.selectedEleves.map(e => e.calendrier.id))] : [this.$store.state.User.profile.calendrierDevoir.id]
      const done = []
      for (let j = 0; j < listCal.length; j++) {
        const listEleve = this.selectedEleves && this.selectedEleves.length > 0 ? [...new Set(this.selectedEleves.filter(c => c.calendrier.id === listCal[j]).map(e => e.type === 'classe' ? [...e.eleves] : e.id).flat())] : []
        const attrs = {
          statut: 'ACTIF',
          titre: this.titre && this.titre !== '' ? this.titre : this.selectedTableaux.titre,
          description: this.description,
          debutAt: this.dateDebut ? new Date(this.dateDebut + 'T00:00:00').toJSON() : null,
          finAt: this.dateFin ? new Date(this.dateFin + 'T00:00:00').toJSON() : null,
          identifiant,
          contenu: {
            id: dossier,
            type: 'DOSSIER'
          }
        }
        const idCalendrier = listCal[j]
        const calendrierElement = () => {
          return new Promise((resolve, reject) => {
            this.createCalendrierElement(idCalendrier, attrs)
              .then((data) => {
                if (data && data.id) {
                  resolve(data)
                } else {
                  this.error = true
                  reject(new Error())
                }
              })
              .catch((e) => {
                this.error = true
                reject(new Error())
              })
          })
        }
        const ajoutUsager = (idCalendrier) => {
          return new Promise((resolve, reject) => {
            this.ajoutUsagerToElement(idCalendrier, listEleve)
              .then((data) => {
                if (!data) {
                  resolve()
                } else {
                  this.error = true
                  reject(new Error())
                }
              })
              .catch((e) => {
                this.error = true
              })
          })
        }
        await calendrierElement()
          .then(
            (data) => {
              ajoutUsager(data.id)
              done.push(j)
            }
          )
          .catch((e) => {
            this.error = true
            console.error(e)
          })
      }
      this.creationLoading = false
      if (this.error) {
        this.showError = true
      } else {
        this.$store.commit('Dialogs/setSelectedEleves', [])
        this.$store.commit('Dialogs/resetSelectedTableaux')
        this.finish = true
        this.$router.push({ name: 'tableauDetail___', params: { id: identifiant } })
      }
    },
    async createCalendrierElement (calendrier, attrs) {
      try {
        return await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/v2/createCalendrierElement.gql'),
          variables: {
            calendrier,
            attrs
          }
        }).then(({ data }) => {
          if (!data.createCalendrierElement || data.createCalendrierElement.errors) {
            throw data.createCalendrierElement.errors ? data.createCalendrierElement.errors : null
          } else {
            return data.createCalendrierElement
          }
        })
      } catch (e) {
        console.error(e)
      }
    },
    async ajoutUsagerToElement (id, usagers) {
      let error = false
      try {
        await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/v2/ajouterUsagerCalendrierElement.gql'),
          variables: {
            id,
            usagers
          }
        }).then(({ data }) => {
          if (!data.ajouterUsagerCalendrierElement || data.ajouterUsagerCalendrierElement.errors) {
            throw data.ajouterUsagerCalendrierElement.errors ? data.ajouterUsagerCalendrierElement.errors : null
          } else {
            return data.ajouterUsagerCalendrierElement
          }
        })
      } catch (e) {
        console.error(e)
        error = true
      }
      return error
    },
    async createDossier () {
      let error = false
      try {
        await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/v2/createSalleClasseEnregistrementDossier.gql'),
          variables: {
            attrs: {
              titre: this.titre,
              description: this.description
            }
          }
        }).then(({ data }) => {
          if (!data.createSalleClasseEnregistrementDossier || data.createSalleClasseEnregistrementDossier.errors) {
            throw data.createSalleClasseEnregistrementDossier.errors ? data.createSalleClasseEnregistrementDossier.errors : null
          } else {
            error = data.createSalleClasseEnregistrementDossier.id
          }
        })
      } catch (e) {
        console.error('erreur creation dossier', e)
        error = true
      }
      return error
    },
    async createTableau (id) {
      let error = false
      const defaultEmptyTableauData = [{
        id: 1,
        dokoma_version: 1,
        data: '{}',
        gridProperties: {
          activeGrid: null,
          gridColor: '#000000',
          gridOpacity: 0.25,
          tableauBgColor: '#ffffff'
        }
      }]
      let dataToCopy = JSON.stringify(defaultEmptyTableauData)
      try {
        await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/v2/createSalleClasseEnregistrementTableauBlanc.gql'),
          variables: {
            dossier: id,
            titre: this.titre && this.titre !== '' ? this.titre : this.selectedTableaux.titre,
            data: dataToCopy
          }
        }).then(({ data }) => {
          if (!data.createSalleClasseEnregistrementTableauBlanc || data.createSalleClasseEnregistrementTableauBlanc.errors) {
            throw data.createSalleClasseEnregistrementTableauBlanc.errors ? data.createSalleClasseEnregistrementTableauBlanc.errors : null
          } else {
            error = data.createSalleClasseEnregistrementTableauBlanc.id
          }
        })
      } catch (e) {
        console.error('erreur creation tableau', e)
        error = true
      }
      return error
    },
    validateUpdate () {
      if (this.$refs.creationForm.validate() && this.selectedTableaux && this.selectedTableaux.length) {
        this.creationLoading = true
        setTimeout(() => {
          this.creationLoading = false
        }, 3000)
      } else {
        console.error('erreur validate update')
      }
    },
    removeTab () {
      this.$store.commit('Dialogs/resetSelectedTableaux')
    }
  }
}
</script>

<template lang='pug'>
  .devoirs-creation-index.split-page
    v-fade-transition
      v-overlay(v-if='creationLoading', fixed, color='black', opacity='0.75', z-index='100')
        div.tc
          div.f3 Création du devoir en cours
          div.mt3 Veuillez patienter
          v-progress-circular.mt4(size='70', width='5', color='primary', indeterminate)

    select-eleves-v2(
      v-if="showSelectEleves"
      :initialSelectedUsagers="selectedSallesUsager"
      @close="showSelectEleves = false"
      @select="onSelectSallesUsager"
      @display="onDisplaySallesUsager"
    )

    .page-header(color='primary')
      .header__inner-content
        v-btn.white--text.mb2(@click="$router.go(-1)" :small='$store.state.App.mobileTemplate' rounded, color='primary')
          font-awesome-icon.mr2.f4(:icon="['fas', 'arrow-left']")
          span Retour
        div.flex-grow-1
        .header-title()
          div.main-title() {{ $route.query && $route.query.id ? 'Modification de' + titre : 'Nouveau tableau' }}

    .page__main-content
      //- Bloc description
      section.small-section
        .section-subtitle__header
          h2.section-subtitle Informations générales
        v-sheet.section-card(id='t-step-1', light, :color='$store.state.Preferences.darkMode ? "light" : "white"')
          v-form(ref='creationForm', id='formCreation', v-model='validForm', lazy-validation)
            v-text-field(v-model='titre', :rules='titreRules' outlined, required, placeholder='tableau sans titre', label='Titre du tableau*')
            v-textarea(v-model='description', outlined, placeholder='description', label='description-tableau')
            v-menu(ref='menuDebut', v-model='menuDateDebut', :rules='dateDebutRules', :close-on-content-click='false', :return-value.sync='dateDebut', transition='scale-transition', offset-y, min-width='290px')
              template(v-slot:activator='{ on, attrs }')
                v-text-field(dense, v-model='dateDebutFormatted', outlined, placeholder='Facultatif', label='Date de début', prepend-icon='mdi-calendar', clearable, readonly, v-bind='attrs', v-on='on')
              v-date-picker(v-model='dateDebut', no-title, scrollable, :max='dateFin', @change='$refs.menuDebut.save(dateDebut)', color='primary')
            v-menu(ref='menuFin', v-model='menuDateFin', :close-on-content-click='false', :return-value.sync='dateFin', transition='scale-transition', offset-y, min-width='290px')
              template(v-slot:activator='{ on, attrs }')
                v-text-field(dense, v-model='dateFinFormatted', outlined, placeholder='Facultatif', label='Date de fin', prepend-icon='mdi-calendar', clearable, readonly, v-bind='attrs', v-on='on')
              v-date-picker(v-model='dateFin', no-title, scrollable, :min='dateDebut', @change='$refs.menuFin.save(dateFin)', color='primary')
            //- COMMENT CAR NON PRÉSENT
            //- v-text-field(dense, v-model='tempsMoyen', outlined, placeholder='--:--', label='Temps moyen')
            //- v-autocomplete(dense, v-model='selectedMatieres', outlined, placeholder='Matières du devoir', label='Matières')
            //- v-autocomplete(dense, v-model='selectedNiveaux', outlined, placeholder='Niveaux du devoir', label='Niveaux')

      //- Bloc contenu
      section.large-section.devoir-content
        .section-subtitle__header
          h2.section-subtitle Contenu du tableau
          //- v-btn(small, outlined, rounded, @click='showTableauSelection = !showTableauSelection') Dupliquer un tableau
        v-sheet.section-card(id='t-step-2', light, :color='$store.state.Preferences.darkMode ? "light" : "white"')
          template(v-if='$route.query.t && $route.query.titre')
            span Copie éditable basée sur le modèle
              b {{ $route.query.titre }}
          template(v-else-if='selectedTableaux')
            //- 1
            //- tableau-preview-expanded(:tableau='selectedTableaux')
            v-btn.mt2(block, rounded, outlined, color='error', @click='removeTab')
              span.mr2 Retirer le tableau
              font-awesome-icon.f5(:icon="['fad', 'trash-alt']")
          template(v-else)
            div.empty-content__wrapper
              div Si vous le désirez, vous pouvez directement importer le contenu d'un autre de vos tableaux.
              v-btn.mt3(rounded, color='primary', @click='showTableauSelection = !showTableauSelection') Importer ce tableau

        //- Bloc eleves
        .section-subtitle__header.mt4
          h2.section-subtitle
            div Sélection des élèves
          v-btn(v-if='selectedSallesUsager && selectedSallesUsager.length' small outlined rounded @click='showSelectEleves = true') eleves.modif-selection
        v-sheet.section-card(id='t-step-3', light, :color='$store.state.Preferences.darkMode ? "light" : "white"')
          template(v-if='selectedSallesUsager && selectedSallesUsager.length')
            //- 2
            group-list-selection-preview(:groupList="displayedSallesUsagers")
          template(v-else)
            div.empty-content__wrapper
              div Votre tableau n'est attribué à aucun élève. Vous pouvez ajouter les élèves maintenant, ou attribuer ce tableau à des élèves plus tard.
              v-btn.mt3(rounded color='primary' @click='showSelectEleves = true') Ajouter des élèves
        .save-button.pv4
          v-btn(@click='validateCreation()', x-large, color='primary', rounded, block) {{ $route.query && $route.query.id ? 'action.modif-tableau' : 'Creer le tableau'}}
          v-alert.mt3(type='error', border='left', :value='!validForm', dense, transition="scale-transition") valider-champs
</template>
<style lang='sass'>
@import 'src/styles/pages/_devoirsCreation'

</style>
